import React from 'react';

import Icon from '../../../styles/atoms/icons';

import { Container, Control } from '../styles/TabbedSwitcherControls.styled';

const TabbedSwitcherControls = ({ tabs, activeTab, setActiveTab }) => {
  const prevButtonDisabled = activeTab === 0;
  const nextButtonDisabled = activeTab === tabs.length - 1;

  const handlePrev = () => {
    if (prevButtonDisabled) return;

    setActiveTab(activeTab - 1);
  };

  const handleNext = () => {
    if (nextButtonDisabled) return;

    setActiveTab(activeTab + 1);
  };

  return (
    <Container>
      <Control
        type="button"
        aria-label="Previous"
        className={`tab-prev ${prevButtonDisabled ? 'disabled' : ''}`}
        onClick={handlePrev}
      >
        <Icon id="FiArrowLeft" />
      </Control>
      <Control
        type="button"
        aria-label="Next"
        className={`tab-prev ${nextButtonDisabled ? 'disabled' : ''}`}
        onClick={handleNext}
      >
        <Icon id="FiArrowRight" />
      </Control>
    </Container>
  );
};

export default TabbedSwitcherControls;
