import styled from 'styled-components';
import { atMinWidth } from '../../../styles/atoms/breakpoints';
import { colors } from '../../../styles/atoms/colors';

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  ${atMinWidth.md`
    display: none;
  `}
`;
export const Control = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border: 1px solid ${colors.gray[200]};
  background: ${colors.base.white};
  border-radius: 50%;
  cursor: pointer;

  svg {
    color: ${colors.gray[500]};
    width: 24px;
    font-size: 24px;
  }

  &.disabled {
    opacity: 0.5;
  }
`;
